import React from "react";
import Form from "../components/Home/Form";
import Hero from "../components/Home/Hero";
import Navbar from "../components/Home/Navbar";

import heroImg from "../assets/hero.jpg";

const Home = () => {
  return (
    <div>
      <div
        className="bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${heroImg})`,
        }}
      >
        <div className="backdrop-blur-sm bg-white/20">
          <Navbar />
          <Hero />
        </div>
      </div>
      <Form />
    </div>
  );
};

export default Home;
