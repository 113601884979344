import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import emailjs from "emailjs-com";

const SERVICE_ID = "matrimony_site";
const TEMPLATE_ID = "template_i91kj89";
const USER_ID = "fNb3UWZ1Qhj_bz3dn";

const Form = () => {
  const [countries, setCountries] = useState([]);
  const form = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const profileFor = [
    "Self",
    "Son",
    "Daughter",
    "Sister",
    "Brother",
    "Sister",
    "Friend",
    "Relative",
  ];

  const religious = [
    "Muslim",
    "Hindu",
    "Christan",
    "Buddhist",
    "Shik",
    "Parsi",
    "Jain",
    "Jewish",
    "Spiritual",
    "No Religion",
    "Other",
  ];

  const motherTongue = [
    "Bahasa Malay",
    "Bahasa Indonesia",
    "Chinese",
    "Tamil",
    "English",
    "Hindi",
    "Punjabi",
    "Bengali",
    "Arabic",
    "Urdu",
    "Nepali",
    "Thai",
    "Telegu",
    "Other",
  ];

  const preferences = ["Malay", "Chinese", "Indian", "Other"];

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((res) => res.json())
      .then((data) => setCountries(data));
  }, []);

  const sendEmail = (data) => {
    // e.preventDefault();

    if (data) {
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
        (result) => {
          toast.success("Email send");
          form.current.reset();
        },
        (error) => {
          toast.error("FAILED...", error);
        }
      );
    }
  };

  return (
    <div className="pt-14">
      <div className="flex justify-center items-center overflow-auto p-10">
        <form
          className="bg-secondary/20 shadow-lg p-10 rounded-2xl flex flex-wrap gap-3 max-w-3xl justify-between"
          onSubmit={handleSubmit(sendEmail)}
          ref={form}
        >
          <h1 className="w-full text-3xl text-rose-500 text-primary mb-5 font-bold">
            Register Here
          </h1>

          {/* ------- Email ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="email">
              Email
            </label>
            <input
              name="email"
              placeholder="Enter your email"
              type="email"
              id="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                  message: "Provide a valid Email",
                },
              })}
            />
            <label className="label">
              {errors.email?.type === "required" && (
                <span className="label-text-alt text-red-500">
                  {errors.email.message}
                </span>
              )}
              {errors.email?.type === "pattern" && (
                <span className="label-text-alt text-red-500">
                  {errors.email.message}
                </span>
              )}
            </label>
          </div>

          {/* ------- Profile for ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="profileFor">
              Profile for
            </label>
            <select name="profileFor" id="profileFor">
              {profileFor.map((profile, index) => (
                <option key={index} value={profile}>
                  {profile}
                </option>
              ))}
            </select>
          </div>

          {/* ------- First name ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="firstName">
              First Name
            </label>
            <input
              name="firstName"
              placeholder="Enter your first name"
              type="text"
              id="firstName"
              {...register("firstName", {
                required: {
                  value: true,
                  message: "First Name is required",
                },
              })}
            />
            <label className="label">
              {errors.firstName?.type === "required" && (
                <span className="label-text-alt text-red-500">
                  {errors.firstName.message}
                </span>
              )}
            </label>
          </div>

          {/* ------- Last name ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              name="lastName"
              placeholder="Enter your last name"
              type="text"
              id="lastName"
              {...register("lastName", {
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              })}
            />
            <label className="label">
              {errors.lastName?.type === "required" && (
                <span className="label-text-alt text-red-500">
                  {errors.lastName.message}
                </span>
              )}
            </label>
          </div>

          {/* ------- Gender ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <h1 className="mb-3">Gender</h1>
            <div className="flex gap-3">
              <div>
                <input
                  type="radio"
                  id="male"
                  value="Male"
                  name="gender"
                  {...register("gender", {
                    required: {
                      value: true,
                      message: "Gender is required",
                    },
                  })}
                />
                <label className="ml-2 text-lg" htmlFor="male">
                  Male
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="female"
                  value="Female"
                  name="gender"
                  {...register("gender", {
                    required: {
                      value: true,
                      message: "Gender is required",
                    },
                  })}
                />
                <label className="ml-2 text-lg" htmlFor="female">
                  Female
                </label>
              </div>
            </div>
            <label className="label">
              {errors.gender?.type === "required" && (
                <span className="label-text-alt text-red-500">
                  {errors.gender.message}
                </span>
              )}
            </label>
          </div>

          {/* ------- Date of Birth ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="dateOfBirth">
              Date of Birth
            </label>
            <input
              name="dateOfBirth"
              placeholder="Select your date"
              type="date"
              id="dateOfBirth"
              {...register("dateOfBirth", {
                required: {
                  value: true,
                  message: "Date of Birth is required",
                },
              })}
            />
            <label className="label">
              {errors.dateOfBirth?.type === "required" && (
                <span className="label-text-alt text-red-500">
                  {errors.dateOfBirth.message}
                </span>
              )}
            </label>
          </div>

          <hr className="w-full mt-2 bg-black" />
          {/* <br className="w-full mt-2 bg-black" /> */}

          {/* ------- Religious ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="religious">
              Religious
            </label>
            <select name="religious" id="religious">
              {religious.map((religion, index) => (
                <option key={index} value={religion}>
                  {religion}
                </option>
              ))}
            </select>
          </div>

          {/* ------- Mother Tongue ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="motherTongue">
              Mother Tongue
            </label>
            <select name="motherTongue" id="motherTongue">
              {motherTongue.map((language, index) => (
                <option key={index} value={language}>
                  {language}
                </option>
              ))}
            </select>
          </div>

          {/* ------- Nationality ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-3" htmlFor="nationality">
              Nationality
            </label>
            <select name="nationality" id="nationality">
              {countries
                .sort((a, b) => a?.name?.common?.localeCompare(b?.name?.common))
                .map(({ name, index }) => (
                  <option key={index} value={name.common}>
                    {name.common}
                  </option>
                ))}
            </select>
          </div>

          {/* ------- Preferences ------- */}
          <div className="flex flex-col w-full max-w-xs">
            <label className="mb-2" htmlFor="preferences">
              Preferences
            </label>
            <select name="preferences" id="preferences">
              {preferences.map((preference, index) => (
                <option key={index} value={preference}>
                  {preference}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-between items-right w-full mt-3">
            <button
              class="inline-block px-6 py-2.5 bg-rose-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-rose-700 hover:shadow-lg focus:bg-rose-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-rose-800 active:shadow-lg transition duration-150 ease-in-out"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
